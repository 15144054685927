import { useState, memo } from 'react';
import useSWR, { useSWRPages } from 'swr';
import { useFetcher } from '../../api/fetcher';
import { Comment } from 'src/app/common/types/Comment';
import NuevoComment from './Comment';
import NuevoCommentForm from './CommentForm';
import { Spinner } from 'src/app/common';

type Props = {
  contentId: number;
  parentId?: number;
  isChild?: boolean;
};

type Pagination = {
  current: {
    offset: number;
    limit: number;
  };
  next?: {
    offset: number;
    limit: number;
  };
};

type CommentsResponse = {
  comments: Comment[];
  pagination: Pagination;
};

const pageLimit = 20;

export default memo(function NeuvoComments({
  contentId,
  parentId,
  isChild = false,
}: Props) {
  const fetcher = useFetcher();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState<string>('');
  const [recentlyPostedCommentId, setRecentlyPostedCommentId] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    pages,
    pageSWRs,
    isLoadingMore,
    isReachingEnd,
    loadMore,
  } = useSWRPages<any, CommentsResponse, any>(
    // page key
    `comments-${contentId}-${parentId}`,

    // page component
    ({ offset, withSWR }) => {
      const url =
        typeof parentId === 'undefined'
          ? `/comments/${contentId}?offset=`
          : `/comments/${contentId}/${parentId}?offset=`;

      const { data } = withSWR(
        // use the wrapper to wrap the *pagination API SWR*
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useSWR(`${url}${offset || 0}&limit=${pageLimit}`, fetcher, {
          refreshInterval: 30000,
        })
      );

      if (!data?.comments) {
        return (
          <span className="block ml-10 md:ml-16 font-bold text-lg text-foreground-secondary h-12 mt-3">
            Loading comments... <Spinner inline />
          </span>
        );
      }

      return data?.comments.map((comment) => {
        return (
          <NuevoComment
            key={comment.id}
            comment={comment}
            contentId={contentId}
            parentId={parentId}
            highlight={recentlyPostedCommentId === comment.id}
          />
        );
      });
    },

    // one page's SWR => offset of next page
    ({ data }) => {
      if (data && data.comments.length < pageLimit) {
        return null;
      }
      return data?.pagination?.next?.offset || null;
    },

    // deps of the page component
    [contentId, parentId, recentlyPostedCommentId]
  );

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const url =
      typeof parentId === 'undefined'
        ? `/comments/${contentId}`
        : `/comments/${contentId}/${parentId}`;
    try {
      setIsSubmitted(false);
      setHasError(undefined);
      setIsSubmitting(true);
      const res = await fetcher(url, {
        body: { comment: e.target['comment']?.value },
        headers: [],
      });
      await pageSWRs[pageSWRs.length - 1]?.revalidate();

      setRecentlyPostedCommentId(res?.data?.comment?.id);
      setIsSubmitting(false);
      setIsSubmitted(true);
    } catch (e) {
      console.error(e);
      setHasError('Sorry, there was a problem submitting your comment.');
      setIsSubmitting(false);
    }
  };

  return (
    <section className={isChild ? 'ml-4 pl-2 md:ml-16 md:pl-0' : ''}>
      {/* If we're top level, so we can show the form at the top to respond to the content item */}
      {!isChild && (
        <NuevoCommentForm
          contentId={contentId}
          parentId={parentId}
          hasError={hasError}
          isSubmitting={isSubmitting}
          isChild={isChild}
          onSubmitForm={handleSubmitForm}
          isSubmitted={isSubmitted}
        />
      )}

      {/* render this page from SWR */}
      {pages}

      <button
        onClick={loadMore}
        disabled={isReachingEnd || isLoadingMore}
        className="block flex flex-row">
        {isLoadingMore ? (
          <></>
        ) : isReachingEnd ? (
          // <span className="block">no more comments</span>
          <></>
        ) : (
          <span className="block ml-10 md:ml-16 font-bold text-lg text-foreground-secondary h-12 mt-3">
            load more comments
          </span>
        )}
      </button>

      {isReachingEnd && isChild && (
        <NuevoCommentForm
          contentId={contentId}
          parentId={parentId}
          hasError={hasError}
          isSubmitting={isSubmitting}
          isChild={isChild}
          onSubmitForm={handleSubmitForm}
          isSubmitted={isSubmitted}
        />
      )}
    </section>
  );
});
