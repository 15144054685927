import React from 'react';
import Link from 'next/link';
import { dangerousHTML, sizeCloudinaryImage } from '../../common';

function BundleCard({ bundle, wide = false }) {
  const blockStyle = wide
    ? `flex w-full md:w-4/5 max-w-screen-sm`
    : `flex w-full md:w-1/2 xl:w-1/3`;

  return (
    <div className={blockStyle}>
      <div
        className="flex flex-col shadow-lg rounded overflow-hidden bg-bg-primary hover:shadow-2xl transition duration-150 mb-8 md:mx-4 lg:mx-6"
        data-test="bundle-card">
        <Link
          href={`/subscribe/[bundleId]`}
          as={`/subscribe/${bundle.uniqueName}`}>
          <a className="block">
            {bundle.imageUrl && (
              <img
                src={sizeCloudinaryImage(bundle.imageUrl, 600)}
                alt={bundle.title}
              />
            )}
            <div className="px-6 pt-6 xl:px-8 xl:pt-8">
              <h2 className="text-3xl font-bold mb-4 md:mb-6 tracking-tight leading-tight">
                {bundle.title}
              </h2>

              <div
                className="text-lg text-foreground-secondary leading-normal"
                dangerouslySetInnerHTML={dangerousHTML(bundle.description)}
              />
            </div>
          </a>
        </Link>
        <Link
          href={{ pathname: `/subscribe/[bundleId]` }}
          as={`/subscribe/${bundle.uniqueName}`}
          passHref>
          <a className="text-shadow bg-accent-primary mt-auto text-white mb-8 xl:mb-8 hover:bg-accent-darker transition-colors duration-150 inline-block px-4 py-3 rounded font-bold text-xl focus:shadow-outline mx-6 md:mx-6 text-center tracking-wider">
            Subscribe
          </a>
        </Link>
      </div>
    </div>
  );
}

export default BundleCard;
