import createEntityReducer from '../createEntityReducer';

import {
  REQ_BUNDLES,
  RES_BUNDLES_FAILURE,
  RES_BUNDLES_SUCCESS,
  REQ_BUNDLE_PLANS,
  RES_BUNDLE_PLANS_FAILURE,
  RES_BUNDLE_PLANS_SUCCESS,
} from './subscribe.constants';

export const BundleEntityReducer = createEntityReducer(
  { key: 'bundles', id: 'uniqueName' },
  [REQ_BUNDLES, RES_BUNDLES_SUCCESS, RES_BUNDLES_FAILURE]
);

export const BundlePlansEntityReducer = createEntityReducer(
  { key: 'bundles', id: 'uniqueName' },
  [REQ_BUNDLE_PLANS, RES_BUNDLE_PLANS_SUCCESS, RES_BUNDLE_PLANS_FAILURE]
);
