import { useState, useEffect } from 'react';
import Link from 'next/link';
import { FaUser } from 'react-icons/fa';
import { Spinner } from 'src/app/common';
import { useSubscription, useChannel } from 'src/app/user/user.hooks';
import UsernameForm from 'src/app/user/components/UsernameSetForm';
import { User } from 'src/app/common/types/User';
import configFeatures from 'src/app/configFeatures';

type Props = {
  onSubmitForm: (e: React.FormEvent) => Promise<void>;
  isSubmitting: boolean;
  contentId: number;
  parentId: number;
  hasError: string;
  isChild?: boolean;
  isSubmitted?: boolean;
};

const LoginToComment = () => (
  <p className="text-foreground-secondary ml-10 md:ml-16 text-lg mt-2 mb-8">
    <Link href="/login">
      <a className="underline">Sign in</a>
    </Link>{' '}
    to post comments
  </p>
);

type SetUsernameToCommentProps = {
  user: User;
};
const SetUsernameToComment = ({ user }: SetUsernameToCommentProps) => {
  return (
    <div className="flex flex-row mb-12">
      <div className="flex-none mr-2 w-8 h-8 [ md:mr-3 md:w-12 md:h-12 ] bg-gray-800 border-2 border-accent-primary content-center shadow flex justify-center align-middle rounded-full text-foreground-primary">
        <FaUser size="16" className="text-foreground-tertiary self-center" />
      </div>
      <div>
        <label htmlFor="username" className="font-bold text-lg">
          Set your username to leave a comment
        </label>

        <UsernameForm user={user} />
      </div>
    </div>
  );
};

export default function CommentForm({
  onSubmitForm: handleSubmitForm,
  contentId,
  parentId,
  isSubmitting,
  hasError,
  isChild = false,
  isSubmitted = false,
}: Props) {
  const [commentText, setCommentText] = useState<string>();
  const { user } = useSubscription();
  const channel = useChannel();

  // users default name is their UID, so if it matches it needs to be set
  const isUsernameRequired =
    parseInt(
      user.profile?.usernamesByChannelUniqueName?.[channel.cleanname],
      10
    ) === user.profile.uid;

  useEffect(() => {
    if (isSubmitted) {
      setCommentText('');
    }
  }, [isSubmitted]);

  if (!user.isAuthed) {
    return <LoginToComment />;
  }

  if (isUsernameRequired && configFeatures.username) {
    return <SetUsernameToComment user={user} />;
  }

  return (
    <form onSubmit={handleSubmitForm} className="mb-6">
      <div className="flex flex-row mt-3">
        {/* The user.profile.imageUrl is always present even if it doesn't exist.*/}
        {/* {user.profile.imageUrl ? (
        <img
          src={user.profile.imageUrl}
          alt={user.profile.name}
          className="w-8 h-8 md:w-12 md:h-12 rounded-full mr-2 md:mr-3"
        />
      ) : ( */}
        <div className="flex-none mr-2 w-8 h-8 [ md:mr-3 md:w-12 md:h-12 ] bg-gray-800 border-2 border-accent-primary content-center shadow flex justify-center align-middle rounded-full text-foreground-primary">
          <FaUser size="16" className="text-foreground-tertiary self-center" />
        </div>
        {/* )} */}
        <textarea
          disabled={isSubmitting}
          className="shadow-xs w-full rounded-lg h-24 p-4 text-base text-foreground-primary bg-bg-primary"
          name="comment"
          placeholder="Type your comment..."
          key={`textarea-${contentId}-${parentId}`}
          value={commentText}
          onChange={(element) => {
            setCommentText(element.target.value);
          }}
        />
      </div>
      <div className="flex flex-row mt-3">
        {hasError && (
          <p className="text-lg ml-10 md:ml-16 text-red-500">{hasError}</p>
        )}
        {isSubmitted && (
          <p className="text-lg ml-10 md:ml-16 text-green-500">
            Your comment was sent successfully!
          </p>
        )}
        <button
          disabled={isSubmitting}
          className="ml-auto p-3 px-4 font-bold rounded bg-accent-primary text-white flex align-middle text-lg">
          {isSubmitting ? (
            <>
              <span className="block mr-4">Posting...</span>
              <Spinner inline />
            </>
          ) : (
            <span>{isChild ? 'Reply' : 'Post'}</span>
          )}
        </button>
      </div>
    </form>
  );
}
