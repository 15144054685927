import { useState } from 'react';
import { format, distanceInWordsToNow } from 'date-fns';
import NeuvoComments from './Comments';
import { Comment } from 'src/app/common/types/Comment';
import { FaUser, FaReply, FaComment } from 'react-icons/fa';

type Props = {
  comment: Comment;
  contentId: number;
  parentId: number;
  highlight: boolean;
};

export default function NeuvoComment({
  comment,
  contentId,
  parentId,
  highlight,
}: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <div key={comment.id}>
      <div className={`rounded mt-3`}>
        <div className="flex flex-row">
          {comment?.user?.imageUrl ? (
            <img
              src={comment.user.imageUrl}
              alt={comment.user.name}
              className="w-8 h-8 md:w-12 md:h-12 rounded-full mr-2 md:mr-3"
            />
          ) : (
            <div className="inline-block flex-none mr-2 md:mr-3 w-8 h-8 md:w-12 md:h-12 bg-gray-500 content-center shadow flex content-center justify-center align-middle rounded-full">
              <FaUser
                size="16"
                className="text-foreground-tertiary self-center"
              />
            </div>
          )}
          <div
            className={`flex-grow bg-bg-primary p-3 rounded-lg shadow-xs ${
              highlight ? 'bg-red-500' : ''
            }`}>
            <div className="flex flex-row">
              <span className="text-base md:text-lg font-extrabold mb-2">
                {comment.user.name}
              </span>
              <span
                className="ml-auto text-foreground-secondary"
                title={format(comment.createdAt * 1000)}>
                {distanceInWordsToNow(comment.createdAt * 1000)} ago
              </span>
            </div>

            <span className="text-base text-foreground-secondary mb-0">
              {comment.content}
            </span>
          </div>
        </div>
      </div>

      {/* no parentId makes it a top level comment, so show reply expand */}
      {!parentId && (
        <button
          className="px-3 ml-8 md:ml-16 text-sm md:text-base py-2 flex"
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}>
          {comment.children === 0 ? (
            <div className="flex flex-row text-foreground-secondary">
              <FaReply size="16" className="self-center" />{' '}
              <span className="inline-block ml-3">
                {isExpanded ? 'Replying...' : 'Reply'}
              </span>
            </div>
          ) : (
            <div className="flex flex-row text-foreground-secondary">
              <FaComment size="16" className="self-center" />{' '}
              <span className="inline-block ml-3">
                {comment.children === 1
                  ? `${comment.children} reply`
                  : `${comment.children} replies`}
              </span>
            </div>
          )}
        </button>
      )}

      {isExpanded && (
        <NeuvoComments
          contentId={contentId}
          parentId={comment.id}
          isChild={true}
        />
      )}
    </div>
  );
}
